var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"player-atc-wrapper",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.isPopup && _vm.changeIframe('destroyIframe')}}},[_c('div',{directives:[{name:"visibility-change",rawName:"v-visibility-change",value:(_vm.visibilityChange),expression:"visibilityChange"}],ref:"playerContainer",staticClass:"player-container",class:[
      {
        'is-story': _vm.isStory,
        isSquare: _vm.isSquare,
        isPortrait: _vm.isPortrait && !_vm.isSquare,
        isPopup: _vm.isPopup,
      },
      { isLoading: _vm.isLoading && _vm.isCurrentVideo },
      { isTrimmedPlaying: _vm.isTrimmedPlaying },
      { isHorizontalSwipe: _vm.isHorizontalSwipe },
    ],style:([
      _vm.isPopup && _vm.isInPreview
        ? 'max-width: 35%;'
        : _vm.isInPreview && _vm.isBubble && !_vm.isFullScreen
        ? 'height: auto !important;'
        : '',
      _vm.borderRadiusStyle,
    ]),on:{"mouseover":function($event){return _vm.hideTitleAndShowControlsOnHover()},"mouseleave":function($event){return _vm.showTitleAndHideControlsOnLeave()}}},[_c('div',{staticClass:"controls-video-container",class:{
        'controls-video-container-shadow': _vm.isEmbed && _vm.format.thumbnailShadow,
      },style:([_vm.borderRadiusStyle, _vm.thumbnailBorderStyle])},[(_vm.showCloseButton)?_c('close-button',{attrs:{"video-index":_vm.videoIndex}}):_vm._e(),(_vm.showPlayerText)?_c('player-text',{attrs:{"video-index":_vm.videoIndex,"is-quick-shop":_vm.showQuickShop}}):_vm._e(),_c('video',{ref:"videoPlayer",class:['vidjet-video', { isFullScreen: _vm.isFullScreen }, { hideVideo: _vm.hideVideo }],style:([..._vm.videoStyle]),attrs:{"preload":"metadata","poster":_vm.thumbnail,"playsinline":""},on:{"timeupdate":function($event){return _vm.getCurrentTime($event)},"click":function($event){return _vm.togglePlayPause()},"error":function($event){return _vm.onError()},"ended":function($event){return _vm.onEnded()},"pause":function($event){return _vm.onPause()},"playing":function($event){return _vm.onPlay()},"canplaythrough":function($event){return _vm.onCanplaythrough()},"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}}),_c('transition',{attrs:{"name":"fade"}},[_c('VideoControls',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.showControls && _vm.currentTime > 0 && !_vm.isTrimmedPlaying && !_vm.isLoading
          ),expression:"\n            showControls && currentTime > 0 && !isTrimmedPlaying && !isLoading\n          "}],attrs:{"isPaused":_vm.player && _vm.player.paused,"isMuted":_vm.isMuted,"currentTime":_vm.currentTime,"video-index":_vm.videoIndex},on:{"pause-video":_vm.togglePlayPause,"update-player-time":_vm.updateCurrentTime}})],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isTrimmedPlaying && !_vm.isLoading && _vm.currentTime > 0),expression:"!isTrimmedPlaying && !isLoading && currentTime > 0"}],staticClass:"control-icon mute-control",class:{
          'inline-control-icon': !_vm.isFullScreen && !_vm.isEmbedMobile,
          'control-icon-embed-without-product': _vm.isEmbed && !_vm.videoHasProducts,
          'control-icon-embed-with-product': _vm.isEmbed && _vm.videoHasProducts,
          'control-icon-embed-with-product-landscape':
            _vm.isEmbed && _vm.videoHasProducts && _vm.isLandscape,
        },on:{"click":function($event){return _vm.toggleMute(!_vm.isMuted)}}},[(_vm.isMuted)?_c('SoundOff',{staticClass:"sound-off-icon"}):_c('MuteIcon',{staticClass:"mute-icon"})],1)],1),_c('PlayPause',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlayPause && !_vm.isLoading),expression:"showPlayPause && !isLoading"}],staticClass:"play-pause-container",attrs:{"is-small":_vm.isStory && !_vm.isPlayerOpened,"showPlayButton":_vm.showPlayButton,"hasNotClicked":_vm.hasNotClicked},nativeOn:{"click":function($event){return _vm.togglePlayPause()},"touchend":function($event){return _vm.togglePlayPause()}}}),(_vm.showQuickShop)?_c('div',{staticClass:"quick-shop-wrapper",style:(_vm.productBackgroundColor)},[_c('div',{staticClass:"product-info",style:(_vm.bottomCornersRadiusStyle)},[_c('img',{staticClass:"quick-shop-product-image",attrs:{"src":_vm.resizedImageUrl || _vm.cta.products[0].imageUrl,"alt":"product-img"}}),_c('div',{staticClass:"product-name-price",style:(_vm.productTextColor)},[_c('p',{staticClass:"product-name"},[_vm._v(" "+_vm._s(_vm.cta.products[0].name.length > 24 ? _vm.decodeText(_vm.cta.products[0].name.substring(0, 24) + "...") : _vm.decodeText(_vm.cta.products[0].name))+" ")]),_c('p',{staticClass:"product-price"},[_vm._v(" "+_vm._s(_vm.displayCurrency(_vm.currency, _vm.cta.products[0].variants[0].price))+" ")])])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          text: _vm.$t('player.productAdded'),
          trigger: 'click',
        }),expression:"{\n          text: $t('player.productAdded'),\n          trigger: 'click',\n        }"}],staticClass:"quick-shop-button",style:([_vm.bottomCornersRadiusStyle, _vm.ctaColorsStyle]),on:{"click":function($event){$event.stopPropagation();return _vm.openAtcForm.apply(null, arguments)}}},[(_vm.isQuickShopLoading)?_c('div',{staticClass:"quick-shop-clicked"},[_c('div',{staticClass:"lds-ring",style:(_vm.ctaColorsStyle)},[_c('div'),_c('div'),_c('div'),_c('div')])]):_c('div',{staticClass:"quick-shop-button"},[_c('ShoppingBag',{staticClass:"shopping-bag",style:(_vm.ctaColorsStyle)}),_c('p',{style:(_vm.ctaColorsStyle)},[_vm._v(_vm._s(_vm.quickShopCtaAction))])],1)])]):_vm._e(),(_vm.showNavigationArrows)?_c('NavigationArrows',{staticClass:"navigation-arrows-container",class:{
        'navigation-arrows-container-embed': _vm.isEmbed,
        'navigation-arrows-container-embed-horizontal-with-products':
          _vm.isEmbed && _vm.isLandscape && _vm.videoHasProducts,
        'navigation-arrows-container-embed-horizontal-without-products':
          _vm.isEmbed && _vm.isLandscape && !_vm.videoHasProducts,
        'inline-arrows-container': !_vm.isFullScreen && !_vm.isEmbedMobile,
      },style:(_vm.isEmbed
          ? {
              right: `calc(8px + ${_vm.format.thumbnailBorders.size}px)`,
            }
          : {}),attrs:{"video-index":_vm.videoIndex}}):_vm._e(),_c('div',{staticClass:"player-interface"},[(_vm.showPlayerInterface)?_c('PlayerInterface',{attrs:{"showTitle":!_vm.showControls && !_vm.isMultiple && !_vm.isFullScreen,"showControls":_vm.showControls || _vm.player.paused,"video-index":_vm.videoIndex,"is-current-video":_vm.isCurrentVideo,"is-cta-within-range":_vm.isCtaWithinRange},on:{"notify-preview":function($event){return _vm.$emit('notify-preview')},"pause-video":function($event){return _vm.player.pause()}}}):_vm._e()],1),(_vm.showAtcForm)?_c('AtcForm',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeAtcForm),expression:"closeAtcForm"}],staticClass:"atc-form-on-thumbnail",attrs:{"video-index":_vm.videoIndex,"product":_vm.cta.products[0],"is-quick-shop":true},on:{"close":_vm.closeAtcForm,"added-to-cart":_vm.ctaClicked,"buy-now-clicked":_vm.ctaClicked}}):_vm._e(),_c('SpinnerLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading && _vm.isCurrentVideo && _vm.isPlayerOpened),expression:"isLoading && isCurrentVideo && isPlayerOpened"}],attrs:{"isBigSpinner":false,"background":"transparent"}})],1),(_vm.showAtcFullScreen && _vm.isCtaWithinRange)?_c('div',{staticClass:"atc-form"},[_c('atc-multiple-products',{staticStyle:{"height":"calc(var(--vh, 1vh) * 100)"},attrs:{"video-index":_vm.videoIndex}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }