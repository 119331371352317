var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('section',{ref:"playerInterface",staticClass:"interface-section",class:{ isFullScreenCta: _vm.isFullScreen }},[_c('player-title',{directives:[{name:"show",rawName:"v-show",value:(Boolean(_vm.title) && _vm.showTitle),expression:"Boolean(title) && showTitle"}]},[_vm._v(" "+_vm._s(_vm.decodeTextPlayer(_vm.title))+" ")]),(_vm.showWatermark)?_c('vidjet-watermark'):_vm._e(),(_vm.showShareButton)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        text: _vm.$t('player.linkCopied'),
        trigger: 'click',
        position: 'left',
      }),expression:"{\n        text: $t('player.linkCopied'),\n        trigger: 'click',\n        position: 'left',\n      }"}],staticClass:"share-button",class:{
        'share-button-inline-carousel':
          !_vm.isFullScreen && _vm.isCarousel && !_vm.isEmbedMobile,
        'share-button-non-fullscreen':
          !_vm.isFullScreen && !_vm.isCarousel && !_vm.isEmbedMobile,
          'share-button-embed-mobile': _vm.isEmbedMobile
      },on:{"click":_vm.shareWebPage}},[_c('Share')],1):_vm._e(),(_vm.cta && Object.keys(_vm.cta).length && _vm.isCtaWithinRange)?_c('div',{ref:"callToAction",staticClass:"call-to-action",class:_vm.computedCallToActionClasses,style:(_vm.bottomMargin)},[(_vm.isCtaButton)?_c('cta-button',{attrs:{"video-index":_vm.videoIndex,"animate":_vm.animationStates[_vm.videoIndex]},on:{"pause-video":function($event){return _vm.$emit('pause-video')}}},[_vm._v(" "+_vm._s(_vm.decodeTextPlayer(_vm.cta.text))+" ")]):(_vm.isATCButton && !_vm.isAtcOpened)?_c('div',{ref:"atcButtonWrapper",staticClass:"atc-button-wrapper",class:[
          {
            'with-arrows': _vm.hasArrows,
            centered: !_vm.isAtcMultipleProducts,
            animate: _vm.animationStates[_vm.videoIndex],
            'carousel-inline-atc-button-wrapper': _vm.isCarousel && !_vm.isFullScreen,
            'inline-atc-button-wrapper':
              !_vm.isFullScreen &&
              !_vm.isCarousel &&
              !_vm.isBubbleAndLargeScreen &&
              !_vm.isEmbedMobile,
            'bubble-inline-atc-button-wrapper':
              (_vm.isPopup || _vm.isBubble || _vm.isEmbed) &&
              !_vm.isFullScreen &&
              !_vm.isBubbleAndLargeScreen &&
              !_vm.isEmbedMobile,
            'carousel-fs-mobile-atc-button-wrapper':
              _vm.isCarousel && _vm.isFullScreen && _vm.isMobile && !_vm.hasArrows,
          },
        ]},[_c('div',{ref:"atcButtonContainer",staticClass:"atc-button-container",class:[
            {
              'atc-button-container-mobile':
                (_vm.isMobile ||
                  !_vm.isFullScreen ||
                  (_vm.isFullScreen && _vm.isHorizontalSwipe)) &&
                !_vm.isBubbleAndLargeScreen,
            },
            { dragging: _vm.isDragging },
            {
              'atc-fullscreen-arrows':
                (_vm.hasArrows && _vm.isFullScreen) ||
                (_vm.hasArrows && _vm.isBubbleAndLargeScreen),
              'carousel-inline-had-arrows':
                !_vm.isFullScreen && _vm.hasArrows && !_vm.isBubbleAndLargeScreen,
            },
          ],on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDrag.apply(null, arguments)},"touchstart":_vm.startDrag,"mousemove":_vm.handleDrag,"touchmove":_vm.handleDrag,"mouseup":_vm.endDrag,"touchend":_vm.endDrag,"mouseleave":_vm.endDrag}},_vm._l((_vm.cta.products),function(product,index){return _c('add-to-cart-button',{key:product._id,ref:`atcButton-${index}`,refInFor:true,staticClass:"product-card",class:{
              'product-card-inline-has-arrows':
                !_vm.isFullScreen && _vm.hasArrows && !_vm.isBubbleAndLargeScreen,
            },attrs:{"product":product,"has-arrows":_vm.hasArrows},on:{"click":function($event){return _vm.toggleAtcFormVisibility(product, index)}},scopedSlots:_vm._u([{key:"left-arrow",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.hasArrows && index > 0 && _vm.currentButtonIndex === index
                ),expression:"\n                  hasArrows && index > 0 && currentButtonIndex === index\n                "}],staticClass:"arrow-between-buttons left-arrow",class:{
                  'inline-arrow':
                    !_vm.isFullScreen &&
                    !_vm.isBubbleAndLargeScreen &&
                    !_vm.isEmbedMobile,
                  'inline-left-arrows': !_vm.isFullScreen,
                },on:{"click":function($event){$event.stopPropagation();return _vm.scrollPrevWithArrow.apply(null, arguments)}}},[(
                    _vm.isFullScreen || _vm.isBubbleAndLargeScreen || _vm.isEmbedMobile
                  )?_c('ArrowHorizontal',{staticClass:"horizontal-arrow"}):_c('ArrowHorizontalSmall',{staticClass:"horizontal-arrow"})],1)]},proxy:true},{key:"right-arrow",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.hasArrows &&
                  index < _vm.cta.products.length - 1 &&
                  _vm.currentButtonIndex === index
                ),expression:"\n                  hasArrows &&\n                  index < cta.products.length - 1 &&\n                  currentButtonIndex === index\n                "}],staticClass:"arrow-between-buttons right-arrow",class:{
                  'inline-arrow':
                    !_vm.isFullScreen &&
                    !_vm.isBubbleAndLargeScreen &&
                    !_vm.isEmbedMobile,
                  'inline-right-arrows': !_vm.isFullScreen,
                },on:{"click":function($event){$event.stopPropagation();return _vm.scrollNextWithArrow.apply(null, arguments)}}},[(
                    _vm.isFullScreen || _vm.isBubbleAndLargeScreen || _vm.isEmbedMobile
                  )?_c('ArrowHorizontal',{staticClass:"horizontal-arrow right-arrow"}):_c('ArrowHorizontalSmall',{staticClass:"horizontal-arrow right-arrow"})],1)]},proxy:true}],null,true)})}),1)]):(_vm.isEmailCollector)?_c('custom-input',{attrs:{"videoIndex":_vm.videoIndex,"animate":_vm.animationStates[_vm.videoIndex]}}):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.showAtcForm)?_c('div',{staticClass:"atc-form"},[_c('AtcForm',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggleAtcFormVisibility),expression:"toggleAtcFormVisibility"}],attrs:{"video-index":_vm.videoIndex,"isManucurist":_vm.isManucurist,"product":_vm.cta.products[
              _vm.productButtonClicked !== null ? _vm.productButtonClicked : 0
            ]},on:{"close":function($event){return _vm.toggleAtcFormVisibility()},"added-to-cart":_vm.hideAtcForm}})],1):_vm._e()]),(_vm.showSwipeIndication && _vm.isMultiple && !_vm.isHorizontalSwipe)?_c('div',{staticClass:"swiping-indication"},[_c('Arrow'),_c('Arrow')],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }